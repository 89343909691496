/* Sass Document */
/*Estilos Footer*/
/*Tipografias*/
/*@font-face
	font-family: "Raleway-Regular"
	src: url(../../Components/fonts/Raleway/Raleway-Regular.eot), url(../../Components/fonts/Raleway/Raleway-Regular.otf), url(../../Components/fonts/Raleway/Raleway-Regular.ttf)*/
body footer {
  margin-top: 180px;
  margin-bottom: 25px;
}

body footer a {
  cursor: pointer;
  border-radius: 50px;
  float: left;
  color: #FFFFFF;
  background-color: #1A3B65;
  margin-left: 8px;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  padding-left: 10px;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

body footer a:hover, body footer a:active, body footer a:focus {
  background-color: #FF8300;
}

body footer a svg {
  color: #FFFFFF;
}

body footer p {
  font-family: 'Raleway-Regular';
}

body footer p img {
  max-width: 80px;
}
