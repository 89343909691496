/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf)*/
body #inicioSection .overlay {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  opacity: 0.7;
}

body #inicioSection .carousel-caption {
  top: 50%;
}

body #inicioSection .carousel-caption img {
  top: -200px;
  position: relative;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #inicioSection .imageBack {
    object-fit: cover;
    min-height: 100vh;
  }
}

@media only screen and (max-width: 500px) {
  body #inicioSection .imageBack {
    object-fit: cover;
    min-height: 100vh;
  }
  body #inicioSection .carousel-caption img {
    top: -80px;
  }
}
