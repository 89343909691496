/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../Components/fonts/Raleway/Raleway-Regular.eot), url(../../Components/fonts/Raleway/Raleway-Regular.otf), url(../../Components/fonts/Raleway/Raleway-Regular.ttf);
}

@font-face {
  font-family: "Raleway-Black";
  src: url(../../Components/fonts/Raleway/Raleway-Black.eot), url(../../Components/fonts/Raleway/Raleway-Black.otf), url(../../Components/fonts/Raleway/Raleway-Black.ttf);
}

/*@supports (padding-top: constant(safe-area-inset-top))
  .body
    --safe-area-inset-top: constant(safe-area-inset-top)
    height: calc(100% + var(--safe-area-inset-top)) */
/*@supports (padding-top: env(safe-area-inset-top))
  .body
    --safe-area-inset-top: env(safe-area-inset-top)
    height: calc(100% + var(--safe-area-inset-top))*/
.device-ios {
  padding-top: 32px;
}

body {
  background-color: #FFFFFF;
  height: 100%;
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

body .img-cover {
  object-fit: cover;
}

body h1 {
  font-size: 40px;
}

@media only screen and (max-width: 500px) {
  body h1 {
    font-size: 30px;
  }
}

@media (orientation: landscape) {
  .device-ios {
    padding: 20rem;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
