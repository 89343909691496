/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf)*/
body #servicios {
  background-color: #FFFFFF;
}

body #servicios h1 {
  font-family: 'Raleway';
  text-transform: uppercase;
}

body #servicios p {
  font-family: 'Raleway';
}

body #servicios .card-decks .card {
  border: none;
  padding: 0;
  margin-bottom: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

body #servicios .card-decks .card:hover, body #servicios .card-decks .card:active, body #servicios .card-decks .card:focus {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 3;
}

body #servicios .card-decks .card:hover .card-body, body #servicios .card-decks .card:active .card-body, body #servicios .card-decks .card:focus .card-body {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(41, 132, 190, 0.6);
  color: #FFFFFF;
}

body #servicios .card-decks .card:hover .card-body svg, body #servicios .card-decks .card:active .card-body svg, body #servicios .card-decks .card:focus .card-body svg {
  display: block;
  fill: #FFFFFF;
  margin-left: 30%;
  max-width: 40%;
}

body #servicios .card-decks .card .card-body {
  position: relative;
  background-color: rgba(41, 132, 190, 0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

body #servicios .card-decks .card .card-body h4 {
  font-family: 'Raleway';
}

body #servicios .card-decks .card .card-body svg {
  fill: #234473;
  max-width: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-transition--moz-transition: all 0.3s ease-in-out;
  -webkit-transition-transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) {
  body #servicios .card-decks .card:hover .card-body svg, body #servicios .card-decks .card:active .card-body svg, body #servicios .card-decks .card:focus .card-body svg {
    max-width: 43%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  body #servicios .card-decks .card .card-body {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(41, 132, 190, 0.6);
    color: #FFFFFF;
  }
  body #servicios .card-decks .card .card-body svg {
    display: block;
    fill: #FFFFFF;
    margin-left: 30%;
    max-width: 40%;
  }
}

@media only screen and (max-width: 575px) {
  body #servicios .card-decks .card .card-body {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(41, 132, 190, 0.6);
    color: #FFFFFF;
  }
  body #servicios .card-decks .card .card-body svg {
    display: block;
    fill: #FFFFFF;
    margin-left: 30%;
    max-width: 40%;
  }
}
