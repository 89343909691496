@charset "UTF-8";
/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf)*/
body #proyectos {
  background-color: #FFFFFF;
  margin-bottom: 100px;
}

body #proyectos #TabContentProyectos .active {
  display: flex;
}

body #proyectos .carousel-inner {
  overflow: visible;
}

body #proyectos h1 {
  margin-top: 80px;
  font-family: 'Raleway';
  text-transform: uppercase;
  margin-bottom: 50px;
}

body #proyectos .galleryCard .card {
  max-height: 100%;
  border: none;
  border-radius: 0;
}

body #proyectos .galleryCard .card .card-body {
  max-height: fit-content;
  background-color: #E6E6E6;
}

body #proyectos .galleryCard .card .card-body h5, body #proyectos .galleryCard .card .card-body h6 {
  font-family: 'Raleway-Black';
  text-transform: uppercase;
}

body #proyectos .galleryCard .card .card-body h5 div, body #proyectos .galleryCard .card .card-body h6 div {
  position: relative;
  right: -20px;
  float: right;
  width: 20%;
  height: 35px;
  background-color: #FF8300;
}

body #proyectos .galleryCard .card .card-body p {
  font-family: 'Raleway-Regular';
}

body #proyectos #CardsProyectos a {
  margin-top: 15px;
  text-decoration: none;
}

body #proyectos #CardsProyectos a:hover .card, body #proyectos #CardsProyectos a:active .card, body #proyectos #CardsProyectos a:focus .card {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

body #proyectos #CardsProyectos .card {
  border: none;
  border-radius: 0;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

body #proyectos #CardsProyectos .card img {
  border-radius: 0;
}

body #proyectos #CardsProyectos .card .card-body {
  background-color: #2984BE;
  color: #FFFFFF;
  font-family: 'Raleway-Black';
  text-transform: uppercase;
}

body #proyectos .slick-dotted.slick-slider {
  margin-bottom: 30px;
}

body #proyectos .slick-dots {
  position: absolute;
  bottom: -15%;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

body #proyectos .slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

body #proyectos .slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

body #proyectos .slick-dots li button:hover, body #proyectos .slick-dots li button:focus {
  outline: none;
}

body #proyectos .slick-dots li button:hover:before, body #proyectos .slick-dots li button:focus:before {
  opacity: 1;
}

body #proyectos .slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  font-family: "slick";
  line-height: 20px;
  text-align: center;
  border: 1px solid #1A3B65 !important;
  height: 9px !important;
  width: 9px !important;
  border-radius: 50px !important;
}

body #proyectos .slick-dots li.slick-active button:before {
  background-color: #1A3B65 !important;
}

@media only screen and (min-width: 1200px) {
  body #proyectos .galleryCard .card {
    padding-right: 0;
  }
  body #proyectos .galleryCard img {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #proyectos .galleryCard .card {
    padding-right: 0;
  }
  body #proyectos .galleryCard img {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #proyectos .galleryCard .card {
    padding-right: 0;
  }
  body #proyectos .galleryCard img {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  body #proyectos .slick-dots {
    position: absolute;
    bottom: -5%;
  }
}

@media only screen and (max-width: 575px) {
  body #proyectos .slick-dots {
    position: absolute;
    bottom: -5%;
  }
}
