/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
/*@font-face
	font-family: "Raleway-Regular"
	src: url(../../Components/fonts/Raleway/Raleway-Regular.eot), url(../../Components/fonts/Raleway/Raleway-Regular.otf), url(../../Components/fonts/Raleway/Raleway-Regular.ttf)*/
body .avancesCard .card {
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35);
  border-bottom: none;
}

body .avancesCard .card .card-header {
  background-color: transparent;
}

body .avancesCard .card .card-header .card-title {
  margin-bottom: 0px;
  font-family: 'Raleway-Regular';
}

body .avancesCard .card .card-body h5 {
  font-family: 'Raleway-Regular';
  text-transform: uppercase;
}

body .avancesCard .card .card-body h4 {
  font-family: 'Raleway-Black';
  text-transform: uppercase;
}

body .avancesCard .card .card-body .card-title {
  font-family: 'Raleway-Regular';
  text-transform: uppercase;
}
