/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf)*/
body #contacto {
  padding-top: 25px;
  margin-bottom: 100px;
  background-color: #FFFFFF;
}

body #contacto #logoContacto {
  margin-bottom: 30px;
  background-color: #FFFFFF;
}

body #contacto #fondoContacto {
  background-color: #FFFFFF;
  background-image: url(/img/jpg/contacto.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

body #contacto #fondoContacto .container {
  top: 40%;
  position: relative;
}

body #contacto #fondoContacto .container .row {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #424343;
}

body #contacto #fondoContacto .container .row h2, body #contacto #fondoContacto .container .row p {
  font-family: 'Raleway-Regular';
}

body #contacto #fondoContacto .container .row h2 {
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) {
  body #contacto #fondoContacto {
    min-height: 400px;
  }
}
