/* Sass Document */
/*Estilos Pacientes Analisis Clinicos*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf)*/
body .modal {
  padding-left: 0px !important;
}

body .modal .modal-dialog {
  margin: 0%;
  width: 100vw !important;
  max-width: 100vw !important;
}

body .modal .modal-dialog .modal-content {
  border-radius: 0px !important;
}

body .modal .modal-dialog .modal-content .modal-header {
  background-color: #1A3B65 !important;
  height: 85px;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz {
  position: relative;
  top: 18px;
  left: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5%;
  opacity: 1 !important;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #FF9E00 !important;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:hover, body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:active, body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:focus {
  background-color: #FFFFFF !important;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:hover div, body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:active div, body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz:focus div {
  background-color: #FF9E00 !important;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div {
  left: 28%;
  right: 28%;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div:first-child {
  -webkit-webkit-transform: rotate(45deg);
  -moz-webkit-transform: rotate(45deg);
  -ms-webkit-transform: rotate(45deg);
  -o-webkit-transform: rotate(45deg);
  webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div:last-child {
  -webkit-webkit-transform: rotate(-45deg);
  -moz-webkit-transform: rotate(-45deg);
  -ms-webkit-transform: rotate(-45deg);
  -o-webkit-transform: rotate(-45deg);
  webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body .modal .modal-dialog .modal-content .modal-header .brand-modal {
  position: relative;
  height: 50px;
  margin-left: 10%;
}

body .modal .modal-dialog .modal-content .modal-footer {
  border-top: none;
  text-align: center;
  height: 80px;
}

body .modal .modal-dialog .modal-content .modal-footer button {
  border-radius: 0px;
  border: none;
  text-transform: uppercase;
  font-family: "Raleway-Black";
  background-color: #1A3B65;
  color: #FFFFFF;
}

body .modal .modal-dialog .modal-content .modal-footer button:hover, body .modal .modal-dialog .modal-content .modal-footer button:active, body .modal .modal-dialog .modal-content .modal-footer button:focus {
  background-color: #FF9E00 !important;
}

@media only screen and (min-width: 1200px) {
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body .modal .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
  }
  body .modal .modal-dialog .modal-content .modal-body p {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 500px) {
  body .modal .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
  }
  body .modal .modal-dialog .modal-content .modal-body p {
    margin-top: 0px;
  }
}
