/* Sass Document */
/*Estilos Pacientes Analisis Clinicos*/
/*Tipografias*/
/*@font-face
	font-family: "Bebas-Neue-Bold"
	src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf)*/
body .pdfWrapper {
  overflow: hidden;
}

body .canvasCss {
  max-height: 700px;
  overflow: scroll;
}

body .pdfControlsWrapper {
  background-color: trasparent !important;
}
