/* Sass Document */
/*Navbar*/
/*Tipografias*/
#Navbar-container {
  background-color: rgba(26, 59, 101, 0.86) !important;
  border-radius: 0px 0px 0px 0px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.63);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.63);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  /* ANIMATED X */
  /* ANIMATED X COLLAPSED */
  /* END ANIMATED X */
}

#Navbar-container #contenedorIconsNavBar {
  margin-left: 8px;
}

#Navbar-container #contenedorIconsNavBar a {
  cursor: pointer;
  border-radius: 50px;
  float: left;
  color: #1A3B65;
  background-color: #FFFFFF;
  margin-left: 8px;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  padding-top: 3px;
  padding-left: 9px;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#Navbar-container #contenedorIconsNavBar a:hover, #Navbar-container #contenedorIconsNavBar a:active, #Navbar-container #contenedorIconsNavBar a:focus {
  background-color: #FF8300;
}

#Navbar-container #contenedorIconsNavBar a:hover svg, #Navbar-container #contenedorIconsNavBar a:active svg, #Navbar-container #contenedorIconsNavBar a:focus svg {
  color: #FFFFFF;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#Navbar-container #contenedorIconsNavBar a svg {
  color: #1A3B65;
}

#Navbar-container .container-fluid {
  max-width: 1170px;
  padding: 0px;
}

#Navbar-container #logoNavbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

#Navbar-container li a {
  font-family: Raleway-Regular,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
  color: #000;
  letter-spacing: 1px;
  font-size: 14px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

#Navbar-container li a:hover, #Navbar-container li a:focus, #Navbar-container li a:active {
  color: #fed136 !important;
}

#Navbar-container .navbar-nav .nav-item .nav-link.active {
  color: #FEB904 !important;
  font-size: 15px;
}

#Navbar-container .navbar-nav .nav-item .nav-link {
  color: #FFFFFF !important;
}

#Navbar-container .navbar-nav .nav-item .nav-link:hover, #Navbar-container .navbar-nav .nav-item .nav-link:target {
  color: #FEB904 !important;
  font-size: 15px;
}

#Navbar-container .dropdown-item.active,
#Navbar-container .dropdown-item:active {
  background-color: transparent !important;
}

#Navbar-container button:focus {
  outline: none !important;
}

#Navbar-container .navbar-toggler {
  padding-right: 25px;
  border: none;
  background: transparent !important;
}

#Navbar-container .navbar-toggler:hover, #Navbar-container .navbar-toggler:active, #Navbar-container .navbar-toggler:focus {
  border: none;
  background: transparent !important;
}

#Navbar-container .navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #FFFFFF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#Navbar-container .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

#Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

#Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

#Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

#Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

#Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

#Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-default {
  position: absolute !important;
  border-bottom: 0px solid #EABF2D !important;
  border-top: 2px solid #EABF2D;
  border-radius: 95px 0px 0px 0px !important;
  z-index: 2;
  width: 100%;
}
